// url
import {
  getArchiveTableData,
  getFullPdf,
  getPartialPdf,
  getOwnershipPdf,
  getPropertyPdf,
  raiseQuery,
  missingReport,
  reportStatus,
  reportRegeneration,
  starredStatus,
  archiveStatus,
  getStatusCount,
} from "../../../common/urls";
// api
import { apiCall } from "../../../common/connect";

// constant
import { GET, POST, ACCESS_TOKEN } from "../../../common/constants";
import { _getStorageValue } from "../../../common/localStorage";

// Get Archive Table Data
export function archiveTableData(
  successArchiveTableDataCallBack,
  failureArchiveTableDataCallBack,
  pageNumber,
  queryParam = "",
  searchParam = "",
) {
  const url = getArchiveTableData(pageNumber, queryParam, searchParam);
  const onSuccess = (response) => {
    let archiveDataTable = response.data;

    successArchiveTableDataCallBack(archiveDataTable);
  };
  const onFailure = (response) => {
    failureArchiveTableDataCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
// getFullPdf
export function getFullPdfData(
  userId,
  requestId,
  reportStatus,
  successgetFullPdfCallBack,
  failuregetFullPdfCallBack,
) {
  const url = getFullPdf(userId, requestId, reportStatus);
  // console.log()
  const onSuccess = (response) => {
    let pdfData = response.data;
    successgetFullPdfCallBack(pdfData);
  };
  const onFailure = (response) => {
    failuregetFullPdfCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
// getPartialPdf
export function getPartialPdfData(
  userId,
  requestId,
  reportStatus,
  successgetPartialPdfCallBack,
  failuregetPartialPdfCallBack,
) {
  const url = getPartialPdf(userId, requestId, reportStatus);
  // console.log()
  const onSuccess = (response) => {
    console.log("pdfsuccess", response.data);
    let pdfData = response.data;
    successgetPartialPdfCallBack(pdfData);
  };
  const onFailure = (response) => {
    failuregetPartialPdfCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// getOwnershipPdf
export function getOwnershipPdfData(
  userId,
  requestId,
  reportStatus,
  successgetOwnershipPdfCallBack,
  failuregetOwnershipPdfCallBack,
) {
  const url = getOwnershipPdf(userId, requestId, reportStatus);
  // console.log()
  const onSuccess = (response) => {
    console.log("ownershippdfsuccess", response.data);
    let pdfData = response.data;
    successgetOwnershipPdfCallBack(pdfData);
  };
  const onFailure = (response) => {
    failuregetOwnershipPdfCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
//getPropertyPdf
export function getPropertyPdfData(
  userId,
  requestId,
  reportStatus,
  successgetPropertyPdfCallBack,
  failuregetPropertyPdfCallBack,
) {
  const url = getPropertyPdf(userId, requestId, reportStatus);
  // console.log()
  const onSuccess = (response) => {
    console.log("propertypdfsuccess", response.data);
    let pdfData = response.data;
    successgetPropertyPdfCallBack(pdfData);
  };
  const onFailure = (response) => {
    failuregetPropertyPdfCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
// raise query
export function Query(
  raiseQueryPayload,
  successRaiseQueryCallBack,
  failureRaiseQueryCallBack,
) {
  const url = raiseQuery();
  // console.log()
  const onSuccess = (response) => {
    let queryStatus = response.data;
    successRaiseQueryCallBack(queryStatus);
  };
  const onFailure = (response) => {
    failureRaiseQueryCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, raiseQueryPayload, onSuccess, onFailure, token);
    }
  });
}

// missing report
export function missingReportApi(
  missingReportPayload,
  successMissingReportCallBack,
  failureMissingReportCallBack,
) {
  const url = missingReport();
  const onSuccess = (response) => {
    console.log("missingReport", response.data);
    let missingReportStatus = response.data;
    successMissingReportCallBack(missingReportStatus);
  };
  const onFailure = (response) => {
    failureMissingReportCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, missingReportPayload, onSuccess, onFailure, token);
    }
  });
}

// report status api
export function tableDropDownStatus(
  userId,
  requestId,
  status,
  successReportStatusCallBack,
  failureReportStatusCallBack,
) {
  const url = reportStatus(userId, requestId, status);
  const onSuccess = (response) => {
    let reportStatus = response.data;
    successReportStatusCallBack(reportStatus);
  };
  const onFailure = (response) => {
    failureReportStatusCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}

// report regeneration api call.
export function regenerateReport(
  reportRegeneratePayload,
  successReportRegenerationCallBack,
  failureReportRegenerationStatusCallBack,
) {
  const url = reportRegeneration();
  const onSuccess = (response) => {
    let reportRegeneration = response.data;
    successReportRegenerationCallBack(reportRegeneration);
  };
  const onFailure = (response) => {
    failureReportRegenerationStatusCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, reportRegeneratePayload, onSuccess, onFailure, token);
    }
  });
}

// starred status api call.
export function starred(
  userId,
  storeStarredStatus,
  successStarredCallBack,
  failureStarredCallBack,
) {
  const url = starredStatus(userId);
  const onSuccess = (response) => {
    let starredResponse = response.data;
    successStarredCallBack(starredResponse);
  };
  const onFailure = (response) => {
    failureStarredCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, storeStarredStatus, onSuccess, onFailure, token);
    }
  });
}

// archive status api call.
export function archive(
  userId,
  storeArchiveStatus,
  successArchiveStatusCallBack,
  failureArchiveStatusCallBack,
) {
  const url = archiveStatus(userId);
  const onSuccess = (response) => {
    let starredResponse = response.data;
    successArchiveStatusCallBack(starredResponse);
  };
  const onFailure = (response) => {
    failureArchiveStatusCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, storeArchiveStatus, onSuccess, onFailure, token);
    }
  });
}

//status count
export function reportStatusCount(
  successReportStatusCountCallBack,
  failureReportStatusCountCallBack,
) {
  const url = getStatusCount();
  const onSuccess = (response) => {
    console.log("------", response);
    let reportStatus = response.data;
    successReportStatusCountCallBack(reportStatus);
  };
  const onFailure = (response) => {
    failureReportStatusCountCallBack(response);
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(GET, url, "", onSuccess, onFailure, token);
    }
  });
}
