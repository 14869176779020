import React, { useEffect, useState } from "react";
import { _getStorageValue } from "../../common/localStorage";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { responsiveFontSizes } from "@mui/material/styles";
// mui icon import
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import modal
import Raisequery from "../../PopUps/Raisequery";
// star icon import
import StarIcon from "@mui/icons-material/Star";
// arrow icon import
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import filter buttons
import Filterbuttons from "./filterButtons";
// import archieve data image.
import archiveDataImg from "../../assets/images/archieve-data.svg";
import RequestedIcon from "../../assets/images/explorerTable/requested.svg";
import CompletedIcon from "../../assets/images/explorerTable/Generated.svg";
import PartiallyCompleted from "../../assets/images/explorerTable/Login.svg";
import ErrorIcon from "../../assets/images/explorerTable/errorIcon.svg";
import Notavaliable from "../../assets/images/explorerTable/not-avaliable.svg";
import DelayedIcon from "../../assets/images/explorerTable/Clock.svg";
import InProgressIcon from "../../assets/images/explorerTable/Loader.svg";
import caption from "../../assets/images/explorerTable/caption.svg";
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "./styles.css";
// import Dropdownmenu component
import Dropdownmenu from "./Dropdownmenu";
// report dropdown component import
import Reportdropdown from "./Reportdropdown";
// import status dropdown component
import Status from "./Status";

import { useSelector } from "react-redux";
// toaster component.
import Toaster from "./Toaster";
// map view component.
import Mapview from "./Map-view/Mapview";
// Reportregenerationmodal modal.
import Reportregenerationmodal from "../../PopUps/Report-regeneration/index";
import CustomTooltip from "./CustomToolTip";
const useStyles = makeStyles({
  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  selectedRow: {
    background: "#FF9506",
    animationName: "$blinker",
    animationDuration: "0.7s",
    animationTimingFunction: "cubic-bezier(.5, 0, 1, 1)",
    animationIterationCount: 3,
  },
  root: {
    "& tr": {
      height: "10px",
    },
    "& td": {
      height: "auto !important",
    },
  },
});

const ArchiveTable = (props) => {
  const {
    checkStarredStatus,
    checkPendingStatus,
    checkOnHoldStatus,
    checkApprovedStatus,
    checkDeclinedStatus,
    checkArchiveStatus,
  } = useSelector((state) => state.filterTableComponentReducer);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const classes = useStyles();

  window.index = 0;
  const rotateArror = (id) => {
    if (props.statusDropDown === id) {
      return "rotate(180deg)";
    } else {
      return "rotate(0deg)";
    }
  };
  useEffect(() => {
    window.onload = (event) => {
      setSelectedRow(null);
    };
    try {
      if (props.selectedRowProps.prevPath === "/sageDashboardNotification") {
        setSelectedRow(props.selectedRowProps.refId);
      }
    } catch (err) {
    } finally {
      setTimeout(function () {
        setSelectedRow(null);
      }, 5000);
    }
  }, []);

  // function to conditionally add color based on the status.
  const renderReportStatusColor = (data) => {
    switch (data.dropDownStatus) {
      case "pending":
        return "#E5CF00";
      case "onhold":
        return "#FC7900";
      case "approved":
        return "#25B900";
      case "declined":
        return "#D10000";
      default:
        return data.color;
    }
  };

  // function to handle tooltip title capitalization
  function toolTipTitle(dropdownStatus) {
    var splitStr = dropdownStatus
      ?.toLowerCase()
      .split(",")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(", ");
    return splitStr;
  }

  let storeRadioButtonSelection = useSelector(
    (s) => s.radioButtonSelectionReducer.storeRadioButtonSelection,
  );
  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];

  function renderWidthBasedOnLength() {
    if (
      getDefaultRadioButtonSelection.length === 1 ||
      getDefaultRadioButtonSelection.length === 2
    ) {
      return "70%";
    } else {
      return "90%";
    }
  }
  // table columns stored in an array of objects.
  const getColumns = () => {
    const pageNumberList = [];
    const pageNameList = [];
    const defaultPageList = [];

    for (const item of getDefaultRadioButtonSelection) {
      pageNumberList.push(item.page_no);
      pageNameList.push(item.page_name);
      defaultPageList.push(item.default_page);
    }

    // Reusable helper function for setting cell props
    const getCellProps = () => ({
      style: {
        left: "0",
        background: "#ffffff",
        textAlign: "center",
        borderRight: "2px solid #CECECE",
        zIndex: 100,
        padding: "5px",
      },
    });

    // Reusable helper function for setting cell header props
    const getCellHeaderProps = () => ({
      style: {
        left: 0,
        background: "#EEEEF5",
        textAlign: "center",
        borderRight: "2px solid #CECECE",
        zIndex: 11,
        fontSize: "13px",
      },
    });

    // Helper function to create columns
    const createColumn = (label, name, customBodyRenderLite) => ({
      label,
      name,
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite,
        setCellProps: getCellProps, // Use reusable function
        setCellHeaderProps: getCellHeaderProps, // Use reusable function
      },
    });
    const referenceIdColumn = createColumn(
      "REFERENCE ID", // label
      "refNo", // name
      null, // No custom render function, use default rendering
      "0", // left position for both cell and header
      "20px", // height for cell
      "10px", // height for header
    );
    const regionColumn = createColumn(
      "REGION", // label
      "state", // name
      null, // No custom render function, use default rendering
      "0", // left position for both cell and header
      "20px", // height for cell
      "10px", // height for header
    );
    const renderAddedOn = (value, tableData) => (
      <>
        <div>
          <div>{tableData[value].reqTime}</div>
          <div className="timeStamp">{tableData[value].reqDate}</div>
        </div>
      </>
    );

    // Example usage for "ADDED ON" column
    const addedOnColumn = createColumn(
      "ADDED ON", // label
      "reqTime", // name
      (value) => renderAddedOn(value, props.tableData), // render function
      "0", // left position for both cell and header
      "20px", // height for cell
      "20px", // height for header
    );

    let storeColumns = [
      {
        label: "SELECT",
        name: "Options",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value, id) => {
            const { archiveStatus, starredStatus, dropDownStatus, refNo } =
              props.tableData[value];
            const {
              checkPendingStatus,
              checkOnHoldStatus,
              checkApprovedStatus,
              checkDeclinedStatus,
            } = props;

            const isArchived =
              archiveStatus === true &&
              checkPendingStatus === undefined &&
              checkOnHoldStatus === undefined &&
              checkApprovedStatus === undefined &&
              checkDeclinedStatus === undefined;

            if (refNo === selectedRow) {
              setSelectedRowId(id);
            }

            const renderIcon = () => (
              <Tooltip title={starredStatus ? "Starred" : "Not Starred"}>
                <StarIcon
                  className="custom-icon"
                  sx={{ strokeWidth: 0.1 }}
                  style={{ color: starredStatus ? "#534F96" : "#B4B4B4" }}
                  onClick={() =>
                    props.handleStarIcon(value, props.tableData[value])
                  }
                />
              </Tooltip>
            );

            const renderUnarchive = () => (
              <div className="align-image">
                <Tooltip title="Unarchive">
                  <img
                    src={archiveDataImg}
                    alt="archive image"
                    onClick={() =>
                      props.handleUnarchieveData(props.tableData[value])
                    }
                  />
                </Tooltip>
              </div>
            );

            const renderStatusDropdown = () => (
              <Tooltip
                title={
                  props.statusDropDownToolTip === false
                    ? ""
                    : toolTipTitle(dropDownStatus)
                }
              >
                <div className="status-wrapper">
                  <span
                    style={{
                      backgroundColor: renderReportStatusColor(
                        props.tableData[value],
                      ),
                    }}
                  />
                  <div
                    className="arrow-wrapper"
                    style={{
                      backgroundColor:
                        props.statusDropDown === id ? "#534F96" : "#F3F1F1",
                    }}
                  >
                    <KeyboardArrowDownIcon
                      onClick={() =>
                        props.handleArrowClick(id, props.statusDropDown)
                      }
                      style={{
                        cursor: "pointer",
                        transform: rotateArror(id),
                        transition: "0.2s ease",
                        color: props.statusDropDown === id ? "#fff" : "#534F96",
                      }}
                      className="dont-close-on-outside-click"
                    />
                  </div>
                </div>
              </Tooltip>
            );

            return (
              <div
                className={
                  isArchived
                    ? "custom-elements-width"
                    : "custom-elements-wrapper"
                }
                style={{
                  width: isArchived ? renderWidthBasedOnLength() : "inherit",
                }}
              >
                {isArchived ? (
                  <>
                    {!archiveStatus && renderIcon()}
                    {renderUnarchive()}
                  </>
                ) : (
                  <>
                    {renderIcon()}
                    {renderStatusDropdown()}
                  </>
                )}
                <Status
                  tableId={id}
                  statusDropDown={props.statusDropDown}
                  handleStatusColor={props.handleStatusColor}
                  tableDataColor={props.tableData[value]}
                  statusMenu={props.statusMenu}
                />
              </div>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
              padding: "5px",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      },
    ];
    storeColumns.push(referenceIdColumn);
    storeColumns.push(addedOnColumn);
    storeColumns.push(regionColumn);

    const handlePropertyReport = (storeColumns, props) => {
      const propTooltipMap = {
        0: { title: "Requested", icon: RequestedIcon, color: "#116366" },
        "-1": { title: "In Progress", icon: InProgressIcon, color: "#FF8A00" },
        "-3": { title: "Not Available", icon: Notavaliable, color: "#D10000" },
        1: {
          title: "Generated",
          icon: CompletedIcon,
          color: "#219653",
          onClick: (value) => () =>
            props.exportPropertyPdfTrigger(props.tableData[value].requestId),
        },
        "-2": { title: "Failed", icon: Notavaliable, color: "#D10000" },
      };

      const renderPropertyReport = (value) => {
        const reportStatus = props.tableData[value].propertyReport;
        if (reportStatus in propTooltipMap) {
          const { title, icon, color, onClick } = propTooltipMap[reportStatus];
          return (
            <CustomTooltip
              theme={defaultTheme}
              title={title}
              icon={icon}
              color={color}
              onClick={onClick ? onClick(value) : null}
            />
          );
        }
        return "NA";
      };
      storeColumns.push(
        createColumn("PROPERTY REPORT", "propertyReport", renderPropertyReport),
      );
    };
    const handleSatsourceReport = (storeColumns, props) => {
      storeColumns.push({
        label: "SATSOURCE REPORTS",
        name: "farmReport",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value) => {
            return (
              <>
                {props.tableData[value].farmReport === 0 && (
                  <p
                    style={{
                      color: "#262261",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {
                      <MuiThemeProvider theme={defaultTheme}>
                        <RequestedToolTip title="Requested" arrow>
                          <img src={RequestedIcon} id={index++} />
                        </RequestedToolTip>
                      </MuiThemeProvider>
                    }
                  </p>
                )}
                {props.tableData[value].farmReport === -1 && (
                  <p style={{ color: "#E4B302", cursor: "pointer" }}>
                    {
                      <MuiThemeProvider theme={defaultTheme}>
                        <InProgressTooltip title="In Progress" arrow>
                          <img src={InProgressIcon} id={index++} />
                        </InProgressTooltip>
                      </MuiThemeProvider>
                    }
                  </p>
                )}
                {props.tableData[value].farmReport === 2 && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 10,
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip
                            title=" Partially Generated"
                            arrow
                            className="dropdown-outside-click"
                          >
                            <img
                              src={PartiallyCompleted}
                              id={index++}
                              onClick={(e) => {
                                props.handleGenerateReportDropdown(e);
                              }}
                            />
                          </CompletedTooltip>
                          <Reportdropdown
                            handleBriefReportDownload={
                              props.handleBriefReportDownload
                            }
                            handleDetailedReportDownload={
                              props.handleDetailedReportDownload
                            }
                            tableData={props.tableData[value]}
                            dropdownstate={props.dropdownstate}
                            reportDropdown={props.reportDropdown}
                          />
                        </MuiThemeProvider>
                      }
                    </p>
                  </div>
                )}
                {props.tableData[value].farmReport === 1 && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 10,
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip
                            title="Generated"
                            arrow
                            className="dropdown-outside-click"
                          >
                            <img
                              src={CompletedIcon}
                              id={index++}
                              onClick={(e) => {
                                props.handleGenerateReportDropdown(e);
                              }}
                            />
                          </CompletedTooltip>
                          <Reportdropdown
                            handleBriefReportDownload={
                              props.handleBriefReportDownload
                            }
                            handleDetailedReportDownload={
                              props.handleDetailedReportDownload
                            }
                            tableData={props.tableData[value]}
                            dropdownstate={props.dropdownstate}
                            reportDropdown={props.reportDropdown}
                          />
                        </MuiThemeProvider>
                      }
                    </p>
                  </div>
                )}
                {props.tableData[value].farmReport === 3 && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#116366",
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 10,
                      }}
                    >
                      {
                        <MuiThemeProvider theme={defaultTheme}>
                          <CompletedTooltip
                            title="Generated"
                            arrow
                            className="dropdown-outside-click"
                          >
                            <img
                              src={CompletedIcon}
                              id={index++}
                              onClick={(e) => {
                                props.handleGenerateReportDropdown(e);
                              }}
                            />
                          </CompletedTooltip>
                          <Reportdropdown
                            handleBriefReportDownload={
                              props.handleBriefReportDownload
                            }
                            handleDetailedReportDownload={
                              props.handleDetailedReportDownload
                            }
                            tableData={props.tableData[value]}
                            dropdownstate={props.dropdownstate}
                            reportDropdown={props.reportDropdown}
                          />
                        </MuiThemeProvider>
                      }
                    </p>
                    {props.tableData[value].reportReadStatus === 0 ||
                    props.tableData[value].reportReadStatus === null ? (
                      <div className="clippath-wrapper">
                        <img src={caption} alt="new" />
                      </div>
                    ) : null}
                  </div>
                )}
                {props.tableData[value].farmReport === -2 && (
                  <p style={{ color: "#D10000", cursor: "pointer" }}>
                    {
                      <MuiThemeProvider theme={defaultTheme}>
                        <ErrorTooltip title="Error" arrow>
                          <img src={ErrorIcon} id={index++} />
                        </ErrorTooltip>
                      </MuiThemeProvider>
                    }
                  </p>
                )}
                {props.tableData[value].farmReport === -3 && (
                  <p style={{ color: "#D10000", cursor: "pointer" }}>
                    {
                      <MuiThemeProvider theme={defaultTheme}>
                        <ErrorTooltip
                          title="Not Avaliable: Requested farms are either too small or no boundary is available in the land survey data"
                          arrow
                        >
                          <img src={Notavaliable} id={index++} />
                        </ErrorTooltip>
                      </MuiThemeProvider>
                    }
                  </p>
                )}
                {props.tableData[value].farmReport === null && "NA"}
              </>
            );
          },
          setCellProps: () => ({
            style: {
              height: "40px",
              left: "0",
              background: "#ffffff",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 100,
              padding: "0",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              left: 0,
              background: "#EEEEF5",
              textAlign: "center",
              borderRight: "2px solid #CECECE",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      });
    };

    const handleOwnershipReport = (storeColumns, props) => {
      const ownershipTooltipMap = {
        0: { title: "Requested", icon: RequestedIcon, color: "#2F80ED" },
        "-1": { title: "In Progress", icon: InProgressIcon, color: "#FF8A00" },
        "-4": { title: "Not Available", icon: Notavaliable, color: "#D10000" },
        1: {
          title: "Generated",
          icon: CompletedIcon,
          color: "#219653",
          onClick: (value) => () =>
            props.exportOwnershipPdfTrigger(props.tableData[value].requestId),
        },
        "-2": {
          title: "Partially Generated",
          icon: PartiallyCompleted,
          color: "#219653",
          onClick: (value) => () =>
            props.exportOwnershipPdfTrigger(props.tableData[value].requestId),
        },
        "-3": { title: "Delayed", icon: DelayedIcon, color: "#FF8A00" },
      };

      const renderOwnershipReport = (value) => {
        const reportStatus = props.tableData[value].farmMonitoringReport;
        if (reportStatus in ownershipTooltipMap) {
          const { title, icon, color, onClick } =
            ownershipTooltipMap[reportStatus];
          return (
            <CustomTooltip
              theme={defaultTheme}
              title={title}
              icon={icon}
              color={color}
              onClick={onClick ? onClick(value) : null} // Pass the value to the onClick function
            />
          );
        }
        return "NA";
      };

      storeColumns.push(
        createColumn(
          "OWNERSHIP REPORT",
          "farmMonitoringReport",
          renderOwnershipReport,
        ),
      );
    };

    function handleMoreColumn(storeColumns, props) {
      storeColumns.push({
        label: "MORE",
        name: "MORE",
        options: {
          filter: true,
          sort: false,
          customBodyRenderLite: (value, id) => {
            return (
              <div style={{ position: "relative" }}>
                <Tooltip
                  title={
                    props.optionsDropDownToolTip &&
                    props.optionsDropDownToolTip === false
                      ? ""
                      : "More Options"
                  }
                >
                  <IconButton
                    onClick={() =>
                      props.handleDropdownMenu(
                        id,
                        props.anchorEl,
                        props.tableData[value].refNo,
                      )
                    }
                  >
                    <MoreHorizIcon
                      style={
                        props.toggleIcon === id
                          ? {
                              transform: "rotate(0deg)",
                              cursor: "pointer",
                              transition: "0.2s ease",
                            }
                          : {
                              transform: "rotate(-90deg)",
                              transition: "0.2s ease",
                            }
                      }
                      className="show-dropDown"
                    />
                    <Dropdownmenu
                      anchorEl={props.anchorEl}
                      handleQuery={props.handleQuery}
                      handleMapView={props.handleMapView}
                      handleArchieve={props.handleArchieve}
                      handleReportRegeneration={props.handleReportRegeneration}
                      storeRefNo={props.storeRefNo}
                      addActive={props.addActive}
                      tableData={id}
                      storeRequestId={props.tableData[value].requestId}
                      storeTableRowData={props.tableData[value]}
                      surveyDetails={props.tableData[value].surveyDetails}
                      dropDownOptions={props.dropDownOptions}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },

          setCellProps: () => ({
            style: {
              right: "0",
              height: "20px",
              background: "#ffffff",
              textAlign: "center",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              right: 0,
              height: 20,
              background: "#EEEEF5",
              textAlign: "center",
              zIndex: 11,
              fontSize: "13px",
            },
          }),
        },
      });
    }

    for (let i = 0; i < pageNameList.length; i++) {
      switch (pageNameList[i]) {
        case "property":
          if (defaultPageList[i] === true) {
            handlePropertyReport(storeColumns, props);
          }
          break;

        case "satsource":
          if (defaultPageList[i] === true) {
            handleSatsourceReport(storeColumns, props);
            handleMoreColumn(storeColumns, props);
          }
          break;

        case "ownership":
          if (defaultPageList[i] === true) {
            handleOwnershipReport(storeColumns, props);
            handleMoreColumn(storeColumns, props);
          }
          break;

        case "satsource & ownership":
          if (defaultPageList[i] === true) {
            handleSatsourceReport(storeColumns, props);
            handleOwnershipReport(storeColumns, props);
            handleMoreColumn(storeColumns, props);
          }
          break;

        case "property & ownership":
          if (defaultPageList[i] === true) {
            handlePropertyReport(storeColumns, props);
            handleOwnershipReport(storeColumns, props);
          }
          break;

        default:
          break;
      }
    }

    return storeColumns;
  };
  const tableBodyHeight = props.tableBodyHeight;
  const options = {
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows:
      checkPendingStatus ||
      checkOnHoldStatus ||
      checkApprovedStatus ||
      checkDeclinedStatus
        ? false
        : "multiple",
    // responsive: "scroll",
    resizableColumns: false,
    tableBodyHeight,
    filter: false,
    download: false,
    print: false,
    search: false,
    rowsPerPage: 20,
    count: props.totalRows,
    rowsPerPageOptions: [5],
    rowStyle: { height: 50 },
    page: props.pageNumber,
    rowsSelected: props.storeAllRowsSelected.map((row) => row.index),
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        //setPage(tableState.page);
        props.setPageNumber(tableState.page);
      }
    },
    serverSide: true,
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      props.handleCheckBoxes(
        currentRowsSelected,
        allRowsSelected,
        props.tableData,
      );
    },
    selectToolbarPlacement: "none",
    setRowProps: (row) => {
      if (row[1] === selectedRow) {
        if (selectedRowId != 1) {
          document
            .getElementsByTagName("tr")
            [selectedRowId + 1].scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
        }
        return {
          className: classes.selectedRow,
        };
      }
    },
  };

  const defaultTheme = createTheme();
  const theme1 = createTheme({
    overrides: {
      // MUIDataTable: {
      //   responsiveScroll: {
      //     maxHeight: 'none',
      //   },
      // },
    },
  });

  const CompletedTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#219653",
      zIndex: "2",
    },
  })(Tooltip);
  const InProgressTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#FF8A00",
      zIndex: "2",
    },
  })(Tooltip);

  const ErrorTooltip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#D10000",
      zIndex: "2",
    },
  })(Tooltip);
  const RequestedToolTip = withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white",
      backgroundColor: "#2F80ED",
      zIndex: "2",
    },
  })(Tooltip);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  // function to add validations incase there are no respective cases.
  function renderValidationCheck() {
    if (props.tableData.length) {
      return null;
    } else {
      return statusMessages();
    }
  }

  // function to render the last element header name from the get columns function.
  const renderTableHeaders = () => {
    const renderFilteredLabels = getColumns().filter(
      (labels) => labels.label !== "MORE",
    );
    const lastElement = renderFilteredLabels[renderFilteredLabels.length - 1];
    return lastElement;
  };

  // the earlier code has been heavily refactored. Removed the if else statements and made the code more modular.
  function statusMessages() {
    const generateMessage = (condition, type, additionalText = "") => ({
      check: condition,
      message:
        condition === props.tableData.length
          ? null
          : `No ${type} cases. Marking cases according to decision-making stages will help you keep track of your portfolio. ${additionalText}`,
    });

    const messages = [
      generateMessage(props.tableData.length, ""),
      generateMessage(checkPendingStatus, "Decision Pending"),
      generateMessage(checkOnHoldStatus, "On Hold"),
      generateMessage(checkApprovedStatus, "Approved"),
      generateMessage(checkDeclinedStatus, "Declined"),
      generateMessage(
        checkStarredStatus,
        "starred",
        "Starred cases will help you to have quick access to important cases. Click on the star beside the reference ID.",
      ),
      generateMessage(
        checkArchiveStatus,
        "archive",
        `Archive cases will help you to have quick access to the cases which are kept for long-term retention. Click on the three dots beside the ${renderTableHeaders().label.toLowerCase()} column.`,
      ),
    ];

    for (const { check, message } of messages) {
      if (check) {
        if (
          getDefaultRadioButtonSelection.length >= 1 &&
          getDefaultRadioButtonSelection.length <= 6
        ) {
          return (
            <p className="loader-text">
              <span>{message}</span>
            </p>
          );
        } else {
          return null;
        }
      }
    }
    return null;
  }

  return (
    <div className="archiveTable-sections">
      <Toaster
        showToaster={props.showToaster}
        storeStatusName={props.storeStatusName}
      />
      <Filterbuttons
        handleAllStarred={props.handleAllStarred}
        tableIndex={props.tableIndex}
        status={true}
        handleAllReportRenegerate={props.handleAllReportRenegerate}
        handleAllArchieve={props.handleAllArchieve}
        tableData={props.tableData}
        storeAllRowsSelected={props.storeAllRowsSelected}
        handleAllUnArchieve={props.handleAllUnArchieve}
        handleAllUnstarred={props.handleAllUnstarred}
      />
      <Raisequery
        tableRefId={props.tableRefId}
        modalIsOpen={props.modalIsOpen}
        closePopUp={props.closePopUp}
        checkBoxError={props.checkBoxError}
        handleFormSubmit={props.handleFormSubmit}
        handleCheckboxState={props.handleCheckboxState}
        handleComment={props.handleComment}
        successMessage={props.successMessage}
        disableTextField={props.disableTextField}
        activeButton={props.activeButton}
      />
      <Mapview
        mapViewModal={props.mapViewModal}
        setMapViewModal={props.setMapViewModal}
        getSurveyDetails={props.storeSurveyDetails}
        storeRefNo={props.storeRefNo}
        handleDropDownToogle={props.handleDropDownToogle}
        mapViewDropdown={props.mapViewDropdown}
        setMap={props.setMap}
        map={props.map}
        requestTime={props.requestTime}
        setMapViewDropdown={props.setMapViewDropdown}
        mapViewLoader={props.mapViewLoader}
        setStoreSurveyNoDetails={props.setStoreSurveyNoDetails}
        setStoreIndex={props.setStoreIndex}
      />

      <ThemeProvider theme={theme1}>
        <Reportregenerationmodal
          showReportRegenerationmodal={props.showReportRegenerationmodal}
          handleReportRegenerationCheckboxes={
            props.handleReportRegenerationCheckboxes
          }
          disabledButton={props.disabledButton}
          handleSelectedReportRegeneration={
            props.handleSelectedReportRegeneration
          }
          tableData={props.tableData}
          storeAllRowsSelected={props.storeAllRowsSelected}
          handleCheckboxModalClose={props.handleCheckboxModalClose}
          storeIndividualTableRowData={props.storeIndividualTableRowData}
          showSingleReportFlow={props.showSingleReportFlow}
        />
        {props.tableDataLoader &&
          getDefaultRadioButtonSelection.length >= 1 &&
          getDefaultRadioButtonSelection.length <= 6 && (
            <p className="loader-text">Loading...</p>
          )}
        {renderValidationCheck()}
        <MUIDataTable
          data={props.tableData}
          columns={getColumns()}
          options={options}
        />
        <p className="table-footer-text">
          <img src={PartiallyCompleted} alt="Tooltip Image" /> This represents a
          partially generated report. We are sourcing data from govt website and
          will notify you when the report is fully generated.
        </p>
      </ThemeProvider>
    </div>
  );
};

export default ArchiveTable;
